import { Modal, useTheme, IconButton, Box, Slide } from '@mui/material'
import { ReactNode } from 'react'
import { Close as CloseIcon } from '@mui/icons-material'

interface IProps {
  open: boolean
  handleClose: () => void
  children: ReactNode
  sx?: object
  ariaLable: string
  closeOnParentClick?: boolean
  showCloseButton?: boolean
}

export const CustomModalAnimated = ({
  open,
  handleClose,
  children,
  sx,
  ariaLable,
  closeOnParentClick = false,
  showCloseButton = false,
}: IProps) => {
  const theme = useTheme()

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby={`${ariaLable}-title`}
      aria-describedby={`${ariaLable}-description`}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 100000,
      }}
    >
      <Slide
        onClick={(e) => {
          e.stopPropagation()
        }}
        direction="right"
        in={open}
        mountOnEnter
        unmountOnExit
      >
        <Box
          onClick={(e) => {
            if (closeOnParentClick) {
              e.stopPropagation()
              handleClose()
            }
          }}
          sx={{
            outline: 'none',
            ...sx,
            position: 'relative',
            maxWidth: '100vw',
            bgcolor: theme.palette.background.paper,
          }}
        >
          {children}

          {/* Close Button */}
          {showCloseButton && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation()
                handleClose()
              }}
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
                zIndex: 100,
                color: theme.palette.common.black,
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </Box>
      </Slide>
    </Modal>
  )
}
