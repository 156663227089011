import { Modal, useTheme, IconButton, Box } from '@mui/material'
import { ReactNode } from 'react'
import { Close as CloseIcon } from '@mui/icons-material'

interface IProps {
  open: boolean
  handleClose: () => void
  children: ReactNode
  sx?: object
  ariaLabel: string
  closeOnParentClick?: boolean
  showCloseButton?: boolean
}

export const CustomModal = ({
  open,
  handleClose,
  children,
  sx,
  ariaLabel,
  closeOnParentClick = false,
  showCloseButton = false,
}: IProps) => {
  const theme = useTheme()

  return (
    <Modal
      open={open}
      onClose={handleClose} // Outside click will now work again
      aria-labelledby={`${ariaLabel}-title`}
      aria-describedby={`${ariaLabel}-description`}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 100000,
      }}
    >
      <Box
        onClick={(e) => {
          if (closeOnParentClick) {
            e.stopPropagation()
            handleClose()
          }
        }}
        sx={{
          outline: 'none',
          ...sx,
        }}
      >
        {/* Close Button in the Backdrop Area */}
        {showCloseButton && (
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'fixed',
              top: 4,
              right: 8,
              zIndex: 100,
              color: theme.palette.grey[50],
            }}
          >
            <CloseIcon />
          </IconButton>
        )}

        {children}
      </Box>
    </Modal>
  )
}
