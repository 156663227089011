import { Divider, Typography } from '@mui/material'
import parse from 'html-react-parser'

import { BottomCardTitle, ImpactsCardsHor, MediaList } from 'src/components'

import { IImpact, IMedia } from 'src/types'
import {
  ContentWrapper,
  ImpactsWrapper,
  NameTitle,
  TitleWrapper,
  ContentSt,
  DescriptionWrapper,
  StyledScrollBtn,
} from './product-card.styled'
import ArrowIcon from 'src/assets/icons/arrow.icon'
import ArrowIconLeft from 'src/assets/icons/arrow.icon-left'
import { useRef } from 'react'
import { useIsTablet } from 'src/hooks'

interface IProps {
  accountHolder: string
  accountImage: string
  productName: string
  productDescription: string
  media: IMedia[]
  showMedia: IMedia[]
  impacts: IImpact[]
  hideAccountName: boolean
  hideProductName: boolean
  accountId: string
  primaryColor: string
  walletAddress: string
  showWalletAddress: boolean
  showBcInfo: boolean
}

export const Content = ({
  accountHolder,
  accountImage,
  productName,
  productDescription,
  showMedia,
  impacts,
  hideAccountName,
  hideProductName,
  accountId,
  primaryColor,
  walletAddress,
  showWalletAddress,
  showBcInfo,
}: IProps) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const isTablet = useIsTablet()
  const scrollLeft = () => {
    if (scrollContainerRef) {
      scrollContainerRef.current.scrollLeft += -130
    }
  }
  const scrollRight = () => {
    if (scrollContainerRef) {
      scrollContainerRef.current.scrollLeft += 130
    }
  }

  return (
    <ContentSt>
      <ContentWrapper>
        <TitleWrapper>
          <BottomCardTitle
            hideAccountName={hideAccountName}
            twitterTitle={productName}
            title={accountHolder}
            image={accountImage}
            accountId={accountId}
          />
        </TitleWrapper>
        {!hideProductName && <NameTitle variant="h2">{productName}</NameTitle>}
        {productDescription && productDescription !== '<p><br></p>' ? (
          <>
            <DescriptionWrapper>
              <Typography variant="body1">{parse(productDescription)}</Typography>
            </DescriptionWrapper>
            <Divider sx={{ borderColor: 'rgba(0,0,0,0.08);' }} />
          </>
        ) : null}
        {showMedia.length > 0 && (
          <>
            <MediaList media={showMedia} />
            <Divider sx={{ borderColor: 'rgba(0,0,0,0.08);' }} />
          </>
        )}
      </ContentWrapper>
      <ImpactsWrapper>
        <StyledScrollBtn
          onClick={scrollLeft}
          style={{ display: isTablet || impacts.length < 6 ? 'none' : '' }}
        >
          <ArrowIconLeft />
        </StyledScrollBtn>
        {impacts.length > 0 && (
          <ImpactsCardsHor
            items={impacts}
            ref={scrollContainerRef}
            withArrows={!(isTablet || impacts.length < 6)}
            primaryColor={primaryColor}
            walletAddress={walletAddress}
            showWalletAddress={showWalletAddress}
            showBcInfo={showBcInfo}
          />
        )}
        <StyledScrollBtn
          onClick={scrollRight}
          style={{ display: isTablet || impacts.length < 6 ? 'none' : '' }}
        >
          <ArrowIcon />
        </StyledScrollBtn>
      </ImpactsWrapper>
    </ContentSt>
  )
}
