import ReactDOM from 'react-dom'
import { MutableRefObject, useMemo, useRef } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { IMarkerWithStepName, IStep } from 'src/types'

import { PreviousPage, NextPage } from '../navigation'
import { MapView } from '../map-view'
import { ContainerSt, MapWrapper } from './journey-map.styled'
import { DEFAULT_LANGUAGE, PATHS, ROUTES } from 'src/constants'
import { useTranslation } from 'react-i18next'

interface IProps {
  steps: IStep[]
  satelliteView: boolean
  scrollToRef?: MutableRefObject<HTMLSpanElement>
  markerColor?: string
  isImpacts?: boolean
  isTopDivider?: boolean
  accauntId?: string
}

export const JourneyMap = (props: IProps) => {
  const { simpleProductId } = useParams()
  const [, i18n] = useTranslation()
  const location = useLocation()
  const portalContainerRef = useRef(null)

  const markers = useMemo(
    () =>
      props.steps
        .flatMap<
          IStep['locations'][number] & {
            stepName: string
            stepId: string
            stepLocation?: string
          }
        >((step) =>
          step.locations.map((location) => ({
            ...location,
            stepName: step.name,
            stepLocation: location.location_name,
            stepId: step.id,
          })),
        )
        .filter(
          (location) =>
            location.latitude !== null &&
            location.longitude !== null &&
            location.location_address &&
            location.location_address?.trim() != '',
        )
        .map<IMarkerWithStepName>((location) => ({
          latitude: location.latitude / 10_000,
          longitude: location.longitude / 10_000,
          stepName: location.stepName,
          stepLocation: location.stepLocation,
          stepId: location.stepId,
        })),
    [props.steps],
  )

  if (!portalContainerRef.current) {
    portalContainerRef.current = document.createElement('div')
    portalContainerRef.current.style.cssText = `
      position: absolute;
      top: 15px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 99999;
      max-width: 800px;
      width: 100%;
    `
    document.body.appendChild(portalContainerRef.current)
  }

  return (
    <>
      <ContainerSt>
        <MapWrapper>
          <MapView
            markerColor={props.markerColor}
            satelliteView={props.satelliteView}
            markers={markers}
            steps={props.steps}
            scrollToRef={props.scrollToRef}
          />
        </MapWrapper>
      </ContainerSt>
      {portalContainerRef.current &&
        ReactDOM.createPortal(
          <>
            <PreviousPage
              isTopDivider={props?.isTopDivider}
              isImpactPage
              to={
                location.state?.from
                  ? location.state?.from
                  : location.pathname.replace(`/${PATHS.JOURNEY}`, '')
              }
              absolute
            />

            <NextPage
              isTopDivider={props?.isTopDivider}
              isImpactPage
              to={
                props.isImpacts
                  ? `${location.pathname.replace(ROUTES.JOURNEY, ROUTES.IMPACTS)}`
                  : i18n.language === DEFAULT_LANGUAGE
                    ? `/${ROUTES.ACCOUNT}/${props?.accauntId}`
                    : `/${i18n.language}/${ROUTES.ACCOUNT}/${props?.accauntId}`
              }
              state={{ from: location.pathname, productId: simpleProductId }}
            />
          </>,
          portalContainerRef.current,
        )}
    </>
  )
}
