import { GoogleMap } from '@react-google-maps/api'
import { IMarkerWithStepName, IStep } from 'src/types'
import { fitMapWithinMarkers } from 'src/utils'
import { mapViewSt } from './map-view.styled'
import { CustomMarker } from './custom-marker'
import { MutableRefObject, useRef } from 'react'
import { useMap } from 'src/contexts/journey-map.context'

interface IProps {
  markers: IMarkerWithStepName[]
  satelliteView?: boolean
  steps?: IStep[]
  scrollToRef?: MutableRefObject<HTMLSpanElement>
  markerColor?: string
}

export const MapView = ({ markers, satelliteView, scrollToRef, markerColor }: IProps) => {
  const { setMapRef, setMapCenter } = useMap()
  const mapRef = useRef<google.maps.Map | null>(null)

  const handleLoad = (map: google.maps.Map) => {
    mapRef.current = map
    setMapRef(map)
    if (markers.length === 1) {
      setMapCenter(markers[0].latitude, markers[0].longitude, 6)
      return
    }
    fitMapWithinMarkers(map, markers)
  }

  return (
    <GoogleMap
      onLoad={handleLoad}
      mapContainerClassName={mapViewSt}
      zoom={6}
      options={{
        clickableIcons: false,
        streetViewControl: false,
        zoomControl: false,
        mapTypeControl: false,
        fullscreenControl: true,
        disableDefaultUI: true,
        mapTypeId: satelliteView ? 'satellite' : 'roadmap',
        fullscreenControlOptions: {
          position: google.maps.ControlPosition.RIGHT_BOTTOM,
        },
      }}
    >
      {markers.map((marker, index) => (
        <CustomMarker
          key={index}
          marker={marker}
          markerColor={markerColor}
          satelliteView={satelliteView}
          stepLocation={marker.stepLocation}
          stepName={marker.stepName}
          scrollToRef={scrollToRef}
        />
      ))}
    </GoogleMap>
  )
}
