import { Box, Grid, IconButton, Typography } from '@mui/material'
import { useState } from 'react'
import VideoFileOutlinedIcon from '@mui/icons-material/VideoFileOutlined'

import { PdfStubIcon, UrlIcon } from 'src/assets/icons'
import { CustomModal } from 'src/components/modal'
import { IMedia } from 'src/types'
import { useTranslation } from 'react-i18next'
import { COLORS } from 'src/styles/common'
import { HandClickIcon } from 'src/assets/icons/v2/HandClickIcon'
import { theme } from 'src/providers/theme-provider/theme.provider'

interface IModalItemProps {
  media: IMedia[]
}

export const MediaSection = ({ media = [] }: IModalItemProps) => {
  const [t] = useTranslation()

  const [fullscreenImage, setFullscreenImage] = useState<string | null>(null)
  const [pdfPreview, setPdfPreview] = useState<string | null>(null)
  const [videoPreview, setVideoPreview] = useState<string | null>(null)

  const handleMediaClick = (mediaItem: IMedia) => {
    if (mediaItem.type === 'url') {
      window.open(mediaItem.link, '_blank')
    } else if (mediaItem.type.includes('image')) {
      setFullscreenImage(mediaItem.link || mediaItem.link_medium)
    } else if (mediaItem.type === 'application/pdf') {
      setPdfPreview(mediaItem.link)
    } else if (mediaItem.type === 'video/mp4') {
      setVideoPreview(mediaItem.link)
    }
  }

  const handleCloseFullscreen = () => {
    setFullscreenImage(null)
  }

  const handleClosePdfPreview = () => {
    setPdfPreview(null)
  }

  const handleCloseVideoPreview = () => {
    setVideoPreview(null)
  }

  return (
    <Box sx={{ maxHeight: '100%' }}>
      <Typography sx={{ fontSize: '16px', fontWeight: 600, mt: '4px' }}>
        <strong>{t('blockchain-verified-modal-media-lable')}:</strong>
      </Typography>
      <Grid container spacing={2} sx={{ my: 1 }}>
        {media.map((mediaItem, index) => (
          <Grid item key={index} xs={6} sm={4} md={3}>
            <Box
              sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'start',
                textAlign: 'center',
                cursor: 'pointer',
                transition: 'transform 200ms, filter 200ms',
                willChange: 'transform, filter',
                '&:hover': {
                  filter: 'brightness(90%)',
                  transform: 'scale(0.98)',
                },
              }}
              onClick={() => handleMediaClick(mediaItem)}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                  maxHeight: '120px',
                  maxWidth: '100%',
                  width: '100%',
                  borderRadius: '8px',
                  overflow: 'hidden',
                  border: `1px solid ${COLORS.GREY_LIGHT}`,
                  zIndex: 103,
                }}
              >
                {mediaItem.type.includes('image') ? (
                  <Box sx={{ position: 'relative' }}>
                    <img
                      src={mediaItem.link_medium || mediaItem.link_small || mediaItem.link}
                      alt={mediaItem.title}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                    />
                    <IconButton
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        p: '8px',
                        bgcolor: 'rgba(238, 238, 238, 0.5)',
                      }}
                    >
                      <HandClickIcon size={40} stroke={theme.palette.common.white} />
                    </IconButton>
                  </Box>
                ) : mediaItem.type === 'application/pdf' ? (
                  <PdfStubIcon size="100%" />
                ) : mediaItem.type === 'video/mp4' ? (
                  <VideoFileOutlinedIcon sx={{ width: '100%', height: '100%' }} />
                ) : mediaItem.type === 'url' ? (
                  <UrlIcon size="100%" />
                ) : null}
              </Box>

              <Typography variant="caption" sx={{ p: '6px 12px', wordBreak: 'break-word' }}>
                {mediaItem.title}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
      {/* Fullscreen Modal for Image */}
      <CustomModal
        ariaLabel="modal-image"
        open={!!fullscreenImage}
        handleClose={handleCloseFullscreen}
        closeOnParentClick
        showCloseButton
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          objectFit: 'contain',
          height: '90%',
          width: '90%',
        }}
      >
        <img
          onClick={(e) => {
            e.stopPropagation()
          }}
          src={fullscreenImage}
          alt="Fullscreen view"
          style={{ maxHeight: '100%', maxWidth: '100%' }}
        />
      </CustomModal>
      {/* Fullscreen Modal for PDF */}
      <CustomModal
        ariaLabel="modal-pdf"
        open={!!pdfPreview}
        handleClose={handleClosePdfPreview}
        showCloseButton
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          objectFit: 'contain',
        }}
      >
        <Box
          sx={{
            width: '85vw',
            height: '95vh',
            border: 'none',
            '@media (max-width:768px)': {
              width: '100vw',
              height: '80vh',
            },
          }}
        >
          <iframe
            src={pdfPreview || ''}
            title="PDF Preview"
            style={{
              width: '100%',
              height: '100%',
              border: 'none',
            }}
          />
        </Box>
      </CustomModal>
      {/* Fullscreen Modal for Video */}
      <CustomModal
        ariaLabel="modal-video"
        open={!!videoPreview}
        handleClose={handleCloseVideoPreview}
        closeOnParentClick
        showCloseButton
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          objectFit: 'contain',
          height: '90%',
          width: '90%',
        }}
      >
        <video
          controls
          muted
          style={{
            maxHeight: '100%',
            maxWidth: '100%',
          }}
        >
          <source src={videoPreview || ''} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </CustomModal>
    </Box>
  )
}
