import { MutableRefObject, useState } from 'react'
import { MarkerF, OverlayViewF, OverlayView } from '@react-google-maps/api'
import MapMarkerDark from 'src/assets/files/map-marker-dark.svg'
import MapMarkerLight from 'src/assets/files/map-marker-light.svg'
import { IMarker, IMarkerWithStepName } from 'src/types'
import {
  StepTextHolder,
  StepTextHolderButton,
  StepTextHolderLocationName,
  StepTextHolderName,
} from './custom-marker.styled'
import { useMap } from 'src/contexts/journey-map.context'

interface IProps {
  satelliteView?: boolean
  marker: IMarkerWithStepName | IMarker
  stepName?: string
  stepLocation?: string
  scrollToRef?: MutableRefObject<HTMLSpanElement>
  accountView?: boolean
  markerColor?: string
}

export const CustomMarker = ({
  satelliteView,
  marker,
  stepLocation,
  stepName,
  markerColor,
}: IProps) => {
  const [markOpened, setMarkOpened] = useState<boolean>(false)
  const [isMouseInPopup, setIsMouseInPopup] = useState<boolean>(false)
  const [zIndex, setZIndex] = useState<number>(1)

  const { scrollToRef } = useMap()

  const handleClick = () => {
    if ('stepId' in marker) {
      scrollToRef(marker.stepId)
    }
  }

  const handleMouseEnterMarker = () => {
    setMarkOpened(true)
    setZIndex(1000)
  }

  const handleMouseLeaveMarker = () => {
    if (!isMouseInPopup) {
      setMarkOpened(false)
      setZIndex(1)
    }
  }

  const handleMouseEnterPopup = () => {
    setIsMouseInPopup(true)
  }

  const handleMouseLeavePopup = () => {
    setIsMouseInPopup(false)
    setMarkOpened(false)
    setZIndex(1)
  }

  return (
    <>
      <MarkerF
        icon={
          markerColor
            ? {
                path: 'M14.53 20.26C15.3267 20.2776 16.119 20.1378 16.8616 19.8486C17.6041 19.5594 18.2823 19.1265 18.8572 18.5746C19.432 18.0228 19.8923 17.3629 20.2117 16.6328C20.531 15.9027 20.7031 15.1167 20.718 14.32C20.6971 12.6886 20.0373 11.1305 18.8803 9.98029C17.7233 8.83004 16.1614 8.17936 14.53 8.16797C13.7333 8.15033 12.941 8.29012 12.1984 8.57933C11.4559 8.86854 10.7777 9.30147 10.2028 9.85332C9.62794 10.4052 9.16764 11.0651 8.84832 11.7952C8.529 12.5253 8.35693 13.3112 8.34199 14.108C8.40061 15.7271 9.07245 17.2634 10.2214 18.4057C11.3704 19.5479 12.9106 20.2108 14.53 20.26ZM4.14299 4.13697C6.94856 1.48056 10.6653 0.00012207 14.529 0.00012207C18.3926 0.00012207 22.1094 1.48056 24.915 4.13697C26.2755 5.41709 27.3596 6.96208 28.1006 8.67686C28.8417 10.3916 29.224 12.2399 29.224 14.108C29.224 15.976 28.8417 17.8243 28.1006 19.5391C27.3596 21.2539 26.2755 22.7989 24.915 24.079L14.53 34.05L4.14299 24.079C2.82975 22.7717 1.78767 21.2179 1.07661 19.5068C0.365552 17.7957 -0.000488281 15.961 -0.000488281 14.108C-0.000488281 12.255 0.365552 10.4203 1.07661 8.70915C1.78767 6.99803 2.82975 5.44423 4.14299 4.13697Z',
                scale: 1,
                strokeWeight: 0.2,
                strokeColor: 'black',
                strokeOpacity: 1,
                fillColor: markerColor,
                fillOpacity: 0.85,
                anchor: new window.google.maps.Point(14.53, 34.05),
              }
            : satelliteView
              ? MapMarkerLight
              : MapMarkerDark
        }
        key={marker.latitude + marker.longitude}
        position={{
          lat: marker.latitude,
          lng: marker.longitude,
        }}
        onMouseOver={handleMouseEnterMarker}
        onMouseOut={handleMouseLeaveMarker}
        onMouseDown={() => {
          setMarkOpened(true)
          setZIndex(1000)
        }}
      />
      <OverlayViewF
        position={{ lat: marker.latitude, lng: marker.longitude }}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        getPixelPositionOffset={(width, height) => ({ x: -width / 2, y: -height / 2 })}
        zIndex={zIndex}
      >
        {markOpened && (
          <StepTextHolder onMouseEnter={handleMouseEnterPopup} onMouseLeave={handleMouseLeavePopup}>
            <StepTextHolderLocationName>{stepLocation}</StepTextHolderLocationName>
            <StepTextHolderName>{stepName}</StepTextHolderName>
            <StepTextHolderButton onClick={handleClick}>More</StepTextHolderButton>
          </StepTextHolder>
        )}
      </OverlayViewF>
    </>
  )
}
