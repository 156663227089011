import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import moment from 'moment'

import { useAuthentication, useInjectCss } from 'src/hooks'
import useGoogleAnalytics from 'src/hooks/common/use-google-analytics.hook'

export const RoutesWrapper = () => {
  const { i18n } = useTranslation()
  // const { productId, simpleProductId, accountId } = useParams()
  moment.locale(i18n.language)

  useAuthentication()
  useInjectCss()
  useGoogleAnalytics()

  return <Outlet />
}
