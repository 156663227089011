import { Box, Divider, useMediaQuery, useTheme } from '@mui/material'
import { IMedia, IScan, IUnSdg } from 'src/types'
import parse from 'html-react-parser'
import { ImageCarousel } from '../image-carousel'
import { UnSdgList } from './un-sdg-list.component'
import { LikesCounter } from './likes-counter.component'
import {
  UpperWrapper,
  Item,
  ItemText,
  DescriptionText,
  ItemTitle,
  BottomWrapper,
  CarouselWrapper,
  ItemTextWrapper,
} from './impact-cards-ver.styled'
import { MediaList } from '../media-list'
import { AvatarImg } from '../step-info/step-company.styled'
import { BlockchainVerifiedBanner } from '../blockchain-verified-banner'

interface IProps {
  title: string | number
  subtitle: string
  image: string
  description: string
  media: IMedia[]
  mainMedia: IMedia[]
  unSdg: IUnSdg[]
  likes: number
  impactId: string
  liked: boolean
  quantityName: string
  showUnSdg: boolean
  scans: IScan[]
  primaryColor: string
  walletAddress: string
  showWalletAddress: boolean
  showBcInfo: boolean
}

export const ImpactCard = (props: IProps) => {
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Item id={props.impactId}>
      <UpperWrapper>
        <AvatarImg borderRadius={'10px'} width={'100px'} src={props.image} alt="" />
        {/* <ItemImage
          style={{ borderRadius: 6, backgroundImage: `url(${props.image})`, zIndex: 12 }}
        /> */}
        <div>
          <ItemTextWrapper>
            <ItemTitle variant="h2">{props.title}</ItemTitle>
            <ItemText variant="h4">{props.quantityName}</ItemText>
          </ItemTextWrapper>
          <ItemText variant="subtitle2">{props.subtitle}</ItemText>
        </div>
        {!isTablet && props.showUnSdg && <UnSdgList unSdg={props.unSdg} />}
      </UpperWrapper>
      {props.mainMedia.length ? (
        <CarouselWrapper>
          <ImageCarousel isStep images={props.mainMedia} />
        </CarouselWrapper>
      ) : null}
      <BottomWrapper>
        <LikesCounter count={props.likes} userLiked={props.liked} impactId={props.impactId} />
        {isTablet && props.showUnSdg && (
          <Box mx={'12px'}>
            <UnSdgList unSdg={props.unSdg} />
          </Box>
        )}
        {props.description && props.description !== '<p><br></p>' ? (
          <DescriptionText variant="body1">{parse(props.description)}</DescriptionText>
        ) : null}
        {props.media.length >= 1 && <Divider sx={{ borderColor: 'rgba(0,0,0,0.08);' }} />}
        {props.media.length >= 1 ? <MediaList media={props.media} /> : null}

        {/* <ShareWrapper>
          <TwitterShare title={props.subtitle} />
        </ShareWrapper> */}
        {props.showBcInfo && props.scans && props.scans.length > 0 && (
          <BlockchainVerifiedBanner
            size={40}
            scans={props.scans}
            stroke={props.primaryColor}
            walletAddress={props.walletAddress}
            showWalletAddress={props.showWalletAddress}
          />
        )}
      </BottomWrapper>
    </Item>
  )
}
