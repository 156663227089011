import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
  PageWrapper,
  Loader,
  ImpactsCardsVer,
  PreviousPage,
  NextPage,
  PageContainer,
  PageFooter,
} from 'src/components'
import { useAccount, useImpacts } from 'src/hooks'
import { TopDivider } from '../product/product.styled'
import { LayoutContainer } from 'src/components/layout-container'
import { DEFAULT_LANGUAGE, PATHS, ROUTES } from 'src/constants'
import { useEffect } from 'react'

export default function ImpactsPage() {
  const [, i18n] = useTranslation()
  const { simpleProductId } = useParams()
  const location = useLocation()
  const navigate = useNavigate()

  const { impacts, isLoading: isImpactsLoading, product } = useImpacts()
  const { data: account, isLoading: isAccountLoading } = useAccount(
    product?.account_id,
    i18n.language,
  )

  useEffect(() => {
    if (!isImpactsLoading && product?.name) {
      document.title = `Trackgood Journey ${product?.name} Impacts`
    }
  }, [isImpactsLoading, product?.name])

  useEffect(() => {
    if (impacts && impacts.length === 0 && !isImpactsLoading) {
      if (location.state?.to) {
        navigate(
          i18n.language === DEFAULT_LANGUAGE
            ? `/${ROUTES.ACCOUNT}/${product?.account_simple_id}`
            : `/${i18n.language}/${ROUTES.ACCOUNT}/${product?.account_simple_id}`,
          { state: { from: location.pathname, productId: simpleProductId } },
        )

        return
      }
      navigate(location.pathname.replace(`/${PATHS.IMPACTS}`, ''))
      return
    }
  }, [impacts])

  if (!product || isImpactsLoading || isAccountLoading || !account) {
    return <Loader />
  }

  return (
    <LayoutContainer>
      {account?.alternative_color && <TopDivider bgColor={account.alternative_color} />}

      <PageWrapper>
        <PageContainer>
          {/* <div className={SwiperButtonsHolder}>
            <div className={SwiperButton} onClick={handlePrevious}>
              <LeftArrowIcon />
            </div>
            <LocaleSelectV2 />
            <div className={EmptyButton} />
          </div>
          <ImpactsCardV2 impacts={impacts} product={product} /> */}
          <PreviousPage
            to={
              location.state?.from
                ? location.state?.from
                : location.pathname.replace(PATHS.IMPACTS, PATHS.JOURNEY)
            }
            absolute
          />
          <NextPage
            to={
              i18n.language === DEFAULT_LANGUAGE
                ? `/${ROUTES.ACCOUNT}/${product?.account_simple_id}`
                : `/${i18n.language}/${ROUTES.ACCOUNT}/${product?.account_simple_id}`
            }
            state={{ from: location.pathname, productId: simpleProductId }}
          />
          <ImpactsCardsVer
            items={impacts}
            showUnSdg={account?.show_unsgd_impacts_page}
            primaryColor={account.primary_color}
            walletAddress={account.wallet_address}
            showWalletAddress={account.show_wallet_address}
            showBcInfo={account.show_bc_info}
          />
        </PageContainer>
      </PageWrapper>
      <PageFooter />
      {/* <FooterV2 /> */}
    </LayoutContainer>
  )
}
