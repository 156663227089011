import { Box, useTheme, IconButton, Typography, useMediaQuery, Divider } from '@mui/material'
import { useSwiper } from 'swiper/react'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import { BlockchainVerifiedIcon } from 'src/assets/icons/v2/BlockchainVerifiedIcon'

import { WalletAddress } from 'src/components/wallet-address'

interface IProps {
  handlePrev: (scanLength: number) => void
  handleNext: (scanLength: number) => void
  currentIndex: number
  scanLength: number
  primaryColor?: string
  walletAddress: string
  showWalletAddress: boolean
  slot: string
}

export const BlockchainVerifiedModalHeader = ({
  currentIndex,
  scanLength,
  walletAddress,
  primaryColor,
  showWalletAddress,
  slot,
}: IProps) => {
  const [t] = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const swiper = useSwiper()

  return (
    <div
      slot={slot}
      style={{
        // position: 'sticky',
        // display: 'flex',
        // flexDirection: 'column',
        // justifyContent: 'center',
        // pt: 4,
        // pb: 2,
        // top: 0,
        gap: '3px',
        width: '100%',
        maxWidth: '800px',
        backgroundColor: theme.palette.background.paper,
        margin: '0 auto',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '10px',
          padding: '32px 32px 0px',
        }}
      >
        <Typography variant="h6" sx={{ textAlign: 'center', fontSize: isMobile ? '18px' : '24px' }}>
          {t('blockchain-verified-modal-title')}
        </Typography>
        <Box
          sx={{ width: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <BlockchainVerifiedIcon size={30} stroke={primaryColor} />
        </Box>
      </Box>

      {showWalletAddress && <WalletAddress address={walletAddress} label={'Wallet'} />}

      {scanLength > 1 && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            px: '32px',
            pb: '16px',
          }}
        >
          <IconButton sx={{ p: 0 }} onClick={() => swiper.slidePrev()}>
            <KeyboardArrowLeft fontSize="large" />
          </IconButton>
          <Typography variant="h6" sx={{ textAlign: 'center', flexGrow: 1 }}>
            {currentIndex + 1} of {scanLength}
          </Typography>
          <IconButton sx={{ p: 0 }} onClick={() => swiper.slideNext()}>
            <KeyboardArrowRight fontSize="large" />
          </IconButton>
        </Box>
      )}

      <Divider sx={{ borderColor: 'rgba(0,0,0,0.08);', width: '100%' }} />
    </div>
  )
}
