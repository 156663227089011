import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
  PageWrapper,
  Loader,
  PageContainer,
  PreviousPage,
  AccountDetails,
  AccountInfo,
  PageFooter,
} from 'src/components'
import { useAccount, useAccountProducts, useGetAccountSimpleId } from 'src/hooks'
import { InfoWrapper } from './account.styled'
import { TopDivider } from '../product/product.styled'
import { LayoutContainer } from 'src/components/layout-container'
import { useEffect } from 'react'

export default function Account() {
  const [, i18n] = useTranslation()
  const { accountId } = useParams()
  const navigate = useNavigate()

  const location = useLocation()

  const isUuid = (id: string) => {
    const uuidRegex =
      /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/
    return uuidRegex.test(id)
  }

  const { data: accountSimpleId, isLoading: accountSimpleIdIsLoading } = useGetAccountSimpleId(
    isUuid(accountId) ? accountId : null,
  )

  const { data: account, isLoading: isAccountLoading } = useAccount(accountId, i18n.language)

  const withourProduct = !location.state?.productId
  const { data: products, isLoading } = useAccountProducts(accountId, withourProduct)

  useEffect(() => {
    if (isUuid(accountId)) {
      if (!accountSimpleIdIsLoading && accountSimpleId) {
        navigate(location.pathname.replace(accountId, accountSimpleId), { replace: true })
      }
    }
  }, [accountSimpleIdIsLoading])

  useEffect(() => {
    if (!isAccountLoading && account?.account_holder) {
      document.title = `Trackgood Journey ${account?.account_holder}`
    }
  }, [isAccountLoading, account?.account_holder])

  if (isAccountLoading || !account || isLoading) {
    return <Loader />
  }

  const otherProducts = location.state?.productId
    ? products?.products.filter((p) => p.simple_id.toString() !== location.state?.productId)
    : products?.products

  const currentProduct = location.state?.productId
    ? products?.products.find((p) => p.simple_id.toString() === location.state?.productId)
    : null

  const allProducts = currentProduct ? [currentProduct, ...otherProducts] : products.products

  return (
    <LayoutContainer>
      {account?.alternative_color && <TopDivider bgColor={account.alternative_color} />}
      <PageWrapper>
        {/* <MapProvider>
        <AboutCardV2 />
      </MapProvider> */}

        <PageContainer>
          {/* <PageWrapperInner> */}
          {location.state?.from && <PreviousPage to={location.state?.from} />}
          {/* <TitleSt variant="h1">{t('account-header-title')}</TitleSt> */}
          <InfoWrapper>
            <AccountInfo
              socials={account.socials || {}}
              email={account.email}
              image={account.image_medium || account.image}
              website={account.website}
              location={account.address}
              accountName={account.account_holder}
              walletAddress={account.wallet_address}
              showWalletAddress={account.show_wallet_address}
            />
          </InfoWrapper>
          <AccountDetails
            media={account.media}
            allProducts={allProducts}
            description={account.description}
          />
          {/* </PageWrapperInner> */}
        </PageContainer>
      </PageWrapper>
      <PageFooter accountId={accountId} />
    </LayoutContainer>
  )
}
