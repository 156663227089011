import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useProduct, useProductImpacts } from '../fetch'
import { IImpact } from 'src/types'

export const useImpacts = () => {
  const { productId, simpleProductId } = useParams()
  const [, i18n] = useTranslation()

  const { data: product, isLoading: isProductsLoading } = useProduct(
    productId,
    i18n.language,
    simpleProductId,
  )

  const { data: pImpacts, isLoading: isImpactsLoading } = useProductImpacts(
    product?.id,
    i18n.language,
  )

  const impacts = pImpacts?.impacts || []
  const productImpacts = product?.impacts || []

  const allImpacts = [...impacts, ...productImpacts]

  const filteredImpacts = allImpacts.filter((impact) => impact.impacts_page_show)

  const uniqueImpacts = {}

  filteredImpacts.forEach((obj) => {
    if (!uniqueImpacts[obj.id]) {
      uniqueImpacts[obj.id] = obj
    }
  })

  const impactsToShow = Object.values(uniqueImpacts) as IImpact[]
  return {
    isLoading: isProductsLoading || isImpactsLoading,
    impacts: impactsToShow,
    isImpacts: impactsToShow.length > 0,
    product: product,
  }
}
